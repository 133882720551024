<template>
  <div class="app-container">
    <el-row :gutter="24">
      <div class="chatList-item" v-for="(quData,i) in quDataList" :key="i"
           style="margin-bottom: 24px;position: relative">
        <img class="determineImg" src="@/assets/images/correct.png" v-if="quData.boolean">
        <img class="determineImg" src="@/assets/images/error.png" v-else>
        <el-col :span="18" :xs="24" style="float: unset;margin: auto">
          <el-card class="qu-content">
            <p v-if="quData.topicName">{{ i + 1 }}.{{ quData.topicName }}</p>
            <div v-if="quData.topicType === '1' || quData.topicType==='3'">
              <el-radio-group v-model="quData.answer" :disabled="true" style="width: 100%;">
                <el-radio v-for="(item,index) in quData.eduQuestionsAnswersMap.answerList" :key="index" :label="item.id">{{
                    item.answerOption
                  }}.{{ item.answerOptionValue }}
                </el-radio>
              </el-radio-group>
            </div>
            <div v-if="quData.topicType === '2'">
              <el-checkbox-group v-model="quData.answer" :disabled="true">
                <el-checkbox v-for="(item,index) in quData.eduQuestionsAnswersMap.answerList" :key="index" :label="item.id">{{
                    item.answerOption
                  }}.{{ item.answerOptionValue }}
                </el-checkbox>
              </el-checkbox-group>
            </div>
            <p style="color: #f74e57">正确答案：{{ quData.eduQuestionsAnswersMap.answerOption }}</p>
            <p style="color: #f74e57">答案解析：{{ quData.eduQuestionsAnswersMap.analysis }}</p>
          </el-card>
        </el-col>
      </div>
    </el-row>
  </div>

</template>

<script>
import {getExamPaper} from "@/api/training/edupaperquestion";

export default {
  data() {
    return {
      quDataList: [],
    }
  },
  created() {
    console.log(123123)
    if (this.$route.query.row) {
      let row = JSON.parse(this.$route.query.row);
      this.fetchData(row.paperId,row.id)
    }
  },
  methods: {
    getUniqueValues(arr1, arr2) {
      return arr1.filter(item => !arr2.includes(item))
        .concat(arr2.filter(item => !arr1.includes(item)));
    },
    fetchData(paperId,id) {
      getExamPaper(paperId,id).then(res => {
        let data = res.data.data;
        this.quDataList = this.quDataList.concat(data.judgeList);
        this.quDataList = this.quDataList.concat(data.multiList);
        this.quDataList = this.quDataList.concat(data.radioList);
        this.quDataList.forEach((item) =>{
          if (item.answer != '' && item.topicType != '2' && item.answer == item.correctAnswer) {
            item.boolean = true
          } else {
            item.boolean = false
          }
          if (item.answer != '' && item.topicType == '2') {
            item.answer = item.answer.split(',');
            item.correctAnswer = item.correctAnswer.split(',');
            let list = this.getUniqueValues(item.answer,item.correctAnswer);
            if (list.length == 0) {
              item.boolean = true
            } else {
              item.boolean = false
            }
          }
        })
        console.log(this.quDataList,'73')
      })
    }
  }
}
</script>

<style scoped lang="scss">

.qu-content div {
  line-height: 30px;
}

.el-checkbox-group label, .el-radio-group label {
  width: calc(100% - 30px);
}

.el-checkbox + .el-checkbox {
  margin-left: 0px;
}

.el-button + .el-button {
  margin-left: 12px;
}

.card-title {
  background: #eee;
  line-height: 35px;
  text-align: center;
  font-size: 14px;
}

.card-line {
  padding-left: 10px
}

.card-line span {
  cursor: pointer;
  margin: 2px;
}

.el-radio, .el-checkbox {
  padding: 9px 20px 9px 10px;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
  margin-bottom: 10px;
}

.is-checked {
  border: #409eff 1px solid;
}

.el-radio img, .el-checkbox img {
  max-width: 200px;
  max-height: 200px;
  border: #dcdfe6 1px dotted;
}

.el-checkbox__inner {
  display: none;
}

.el-radio__inner {
  display: none;
}

.el-checkbox__label {
  line-height: 30px;
}

.el-radio__label {
  line-height: 30px;
}

.determineImg {
  position: absolute;
  right: 15%;
  top: 40px;
  width: 170px;
}

::v-deep .is-checked {
  border-color: #dcdfe6;
}
</style>
